<template>
    <!--begin::Table-->
    <div class="table-responsive">
      <table class="table table-head-custom table-vertical-center striped" id="kt_advance_table_widget_4">
        <tbody>
          <template v-for="(row, i) in lineHistory">
            <tr v-bind:key="i">
              <!-- <td style="min-width: 80px">28/11/2020</td> -->
              <td v-if="row.note === 'inspected to Survey'" style="min-width: 100px">
              Inspected on <b>{{row.date}}</b> by <b>{{row.userName}}</b>
              </td>
              <td v-else-if="row.imageName != ''" style="min-width: 100px">
              {{ row.date }} : The image for the <b>{{row.sparePart}}</b> has been uploaded by <b>{{ row.userName }}</b>
              <span v-if="row.note !== ''" class="text-muted font-size-sm">Extra info :</span><span v-if="row.note !== ''" class="text-muted font-weight-bold font-size-sm">"{{ row.note}}"</span>
              <div class="symbol symbol-50 symbol-light mr-2">
              <span class="symbol-label">
              <b-img class="h-50 align-self-center" fluid @click="open(row.imagePath)" v-b-modal.modal-1 v-bind:src="'media/'+row.imagePath" alt="Image 1"></b-img>
              </span>
              </div>
              <!-- <b-img thumbnail fluid @click="open('image_'+i)" v-bind:src="'media/upload/'+companyId+'/'+lineId+'/'+conveyorId+'/'+row.imageName" alt="Image 1"></b-img> -->
                  <!-- <b-img :class="imageName == 'image_'+i ? 'new_img' : 'big_img'" fluid v-bind:src="'media/upload/'+companyId+'/'+lineId+'/'+conveyorId+'/'+row.imageName" alt="Image Big"></b-img> -->
              </td>
              <td v-else style="min-width: 100px">
                {{ row.date }} : The Status for the <b>{{ row.sparePart }}</b> was changed from <b>{{ row.currentStatus }}</b> into 
                <span v-if="row.newStatus === 'green'" class="label label-lg label-inline label-light-success">
                  <span class="label-light-success label-inline font-weight-bold">{{ row.newStatus }}</span>
                </span>
                <span v-if="row.newStatus === 'orange'" class="label label-lg label-inline label-light-warning">
                  <span class="label-light-success label-inline font-weight-bold">{{ row.newStatus }}</span>
                </span>
                <span v-if="row.newStatus === 'red'" class="label label-lg label-inline label-light-danger">
                  <span class="label-light-success label-inline font-weight-bold">{{ row.newStatus }}</span>
                </span>
                by <b>{{ row.userName }}</b>
                <br><span v-if="row.note !== ''" class="text-muted font-size-sm">Extra info :</span><span v-if="row.note !== ''" class="text-muted font-weight-bold font-size-sm">"{{ row.note}}"</span>
              </td>
               <!-- <b-button type="submit" class="mt-3 w-100" variant="primary" size="md" @click="editconveyor(row.id)">Edit</b-button> -->
            </tr>
          </template>
        </tbody>
      </table>
      <div>
        <b-modal id="modal-1">
          <b-img  fluid v-bind:src="imagePath" alt="Image Big"></b-img>
        </b-modal>
      </div>
    </div>
    <!--end::Table-->
</template>

<script>
import JwtService from "@/core/services/jwt.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "widget-1",
  props: ['lineHistory','lineId','conveyorId'],
  data() {
    return {
      companyId: JwtService.authorizeToken().companyId,
      imagePath: ''
      // checked: false,
    };
  },
   methods: {
     open(imagePath){
       this.imagePath = imagePath
    },
      editconveyor(historyId){
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `Home > Line ${this.lineId} > Conveyor ${this.conveyorId} > Maintennance ${historyId}`, route: `/line/${this.lineId}/`, lineId: this.lineId, conveyorId: this.conveyorId, conveyorIds:this.conveyorIds}]);
  this.$router.push(
        "/line/" + this.lineId + "/conveyor/" + this.conveyorId +"/maintenance/"+historyId
      );
        console.log(historyId)
      //  this.imagePath = imagePath
    },
  },
  components: {},
 
};
</script>
