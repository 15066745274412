<template>
  <div>
    <!--begin::Dashboard-->
    <ConveyorTable :lineId="lineId" :conveyorId="conveyorId"></ConveyorTable>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import KTWidget2 from "@/view/pages/dashboard/Widget2";
import ConveyorTable from "@/view/pages/conveyor/ConveyorTable";
import axios from "axios";

export default {
  watch: {},
  // name: "conveyor",
  components: {
    // AdvancedTableWidget2
    // KTWidget2,
    ConveyorTable,
  },
  // data() {
  //   return {
  //   }
  // },
  data() {
    return {
      conveyorId: this.$route.params.conveyorId,
      lineId: this.$route.params.id,
      conveyorIds: [],
    };
  },
  async mounted() {
    await this.getConveyors();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: `Home > Line ${this.lineId} > Conveyor ${this.conveyorId}`,
        route: `/line/${this.lineId}/`,
        lineId: this.lineId,
        conveyorId: this.conveyorId,
        conveyorIds: this.conveyorIds,
      },
    ]);
  },
  methods: {
    async getConveyors() {
      await axios
        .get("line/" + this.lineId)
        .then((response) => {
          const conveyors = response.data;
          for (let conveyor in conveyors) {
            this.conveyorIds.push(conveyors[conveyor].conveyorNumber);
          }
        })
        .catch((err) => {
          throw err;
        });
    },
  },
};
</script>
