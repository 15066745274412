<template>
  <b-dropdown
    size="md"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
    v-b-tooltip.hover.top="'Edit status'"
  >
    <template v-slot:button-content>
      <i class="flaticon2-settings"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover min-w-md-350px">
      <b-dropdown-text tag="div" class="navi-item my-3">
          <span class="navi-text"
            >Changing <b>{{ sparePart }}</b></span
          >
          <!-- now it's {{ newstatus }}<br />
            Current status is : {{ currentStatus }}</span> -->
        <!-- </a> -->
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-separator my-3"></b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item my-3">
        <textarea
          ref="note"
          class="form-control form-control-md form-control-solid"
          type="text"
          v-model="note"
          rows="2"
          placeholder="Add extra note here ..."
        />
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item my-3">
        <b-form-file
          v-model="file"
          :state="file"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          accept="image/png,image/jpeg,image/jpg"
        ></b-form-file>
        <!-- <span class="error" v-if="isImageSize == true">File size exceeds 5 MB</span> -->
        <span class="mt-3 text-danger" v-if="isImageSize == true"> File size exceeds 5 MB </span>
        <b-button
          type="submit"
          class="mt-3 w-100"
          variant="primary"
          size="md"
          @click="UploadImg"
          v-if="file"
          >Upload</b-button
        >
        <!-- <div class="mt-3">Selected file: {{ file ? file.name : "" }}</div> -->
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-separator my-3"></b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a class="navi-link" style="cursor: pointer" @click="submit('green')">
          <span class="navi-icon">
            <i class="flaticon2-check-mark text-success"></i>
          </span>
          <span class="navi-text">Just changed</span>
          <span class="navi-link-badge">
            <span
              class="label label-light-success label-inline font-weight-bold"
            >
              green
            </span>
          </span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a class="navi-link" style="cursor: pointer" @click="submit('orange')">
          <span class="navi-icon">
            <i class="flaticon2-warning text-warning"></i>
          </span>
          <span class="navi-text">Be carefull</span>
          <span class="navi-link-badge">
            <span
              class="label label-light-warning label-inline font-weight-bold"
            >
              orange
            </span>
          </span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a class="navi-link" style="cursor: pointer" @click="submit('red')">
          <span class="navi-icon">
            <i class="flaticon-cancel text-danger"></i>
          </span>
          <span class="navi-text">Must change</span>
          <span class="navi-link-badge">
            <span
              class="label label-light-danger label-inline font-weight-bold"
            >
              red
            </span>
          </span>
        </a>
        <b-alert
          :show="dismissCountDown"
          dismissible
          fade
          variant="success"
          @dismiss-count-down="countDownChanged"
        >
          Status updated successfully
        </b-alert>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import axios from "axios";
export default {
  props: [
    "conveyorNumber",
    "companyId",
    "lineNumber",
    "sparePart",
    "currentStatus",
    "userId",
    "userEmail",
    "userName",
  ],
  name: "dropdown-3",
  data() {
    return {
      submitted: false,
      file: null,
      newstatus: "unchanged",
      note: null,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      isImageSize: false,
    };
  },
  components: {},
  mounted() {},
  methods: {
    // submit form handler
    submit: function (code) {
      var currentUrl = window.location.href;

      this.submitted = true;
      this.newstatus = code;

      // Execute Update Conveyor Status
      axios
        .post("line/updateSparePartStatus", {
          CompanyId: this.companyId,
          LineNumber: this.lineNumber,
          ConveyorNumber: this.conveyorNumber,
          NewStatus: this.newstatus,
          SparePart: this.sparePart,
          CurrentStatus: this.currentStatus,
          UserId: this.userId,
          Note: this.note,
        })
        .then(() => {
          this.dismissCountDown = this.dismissSecs;
          this.note = null;
          // Reload page with new status
          this.$emit("statusChange");
        })
        .catch((err) => {
          throw err;
        });
      let QuoteMessage =
        this.userName +
        " has changed the status from " +
        this.currentStatus +
        " to " +
        this.newstatus +
        " for Conveyor " +
        this.conveyorNumber +
        " " +
        this.sparePart +
        "<br /> <a href=" +
        currentUrl +
        ">Here</a> is the link for your conveyor page.";
      let QuoteSubject =
        "Status Changed: Factory " +
        this.companyId +
        " > " +
        "line " +
        this.lineNumber +
        " > conveyor " +
        this.conveyorNumber;
      if (code == "red") {
        axios
          .post("line/sendLineEdit", {
            UserName: this.userName,
            UserEmail: this.userEmail,
            CompanyId: this.companyId,
            LineNumber: this.lineNumber,
            ConveyorNumber: this.conveyorNumber,
            NewStatus: this.newstatus,
            SparePart: this.sparePart,
            CurrentStatus: this.currentStatus,
            UserId: this.userId,
            QuoteMessage: QuoteMessage,
            QuoteSubject: QuoteSubject,
          })
          .then(() => {})
          .catch((err) => {
            throw err;
          });
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    UploadImg() {
      console.log(this.file);
       if (this.file.size >= '5242880') {
         this.isImageSize = true
        return;
      }else{
         this.isImageSize = false
      }

      const FileName = this.file.name.split(".").slice(0, -1).join(".")
      const extension = this.file.name.substr(this.file.name.lastIndexOf('.') + 1);

      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("SparePart", this.sparePart);
      formData.append("FileName", FileName);
      formData.append("extension", extension);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post(
          "line/fileUploading/" +
            this.companyId +
            "/" +
            this.lineNumber +
            "/" +
            this.userId +
            "/" +
            this.conveyorNumber,
          formData,
          config
        )
        .then(() => {
          this.$emit("statusChange");

          this.fetchLineHistory();
        })
        .catch((err) => {
          throw err;
        });
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    fetchLineHistory() {
      axios
        .get(
          "line/" +
            this.lineNumber +
            "/conveyor/" +
            this.conveyorNumber +
            "/history"
        )
        .then((response) => {
          this.lineHistory = response.data;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
};
</script>
