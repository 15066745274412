<template>
  <b-card no-body class="overflow-hidden" style="max-width: 540px;">
    <b-row no-gutters>
      <b-col md="6" style="max-width: 150px;">
        <b-card-img
          :src="imgUrl"
          class="rounded-0"
        ></b-card-img>
      </b-col>
      <b-col md="6">
        <b-card-body class="p-2">
          <b-card-text>
            <b-badge v-show="CardStatus === 'green'" pill variant="success" class="float-right"><i class="flaticon2-check-mark text-white"></i></b-badge>
            <b-badge v-show="CardStatus === 'orange'" pill variant="warning" class="float-right"><i class="flaticon2-warning text-white"></i></b-badge>
            <b-badge v-show="CardStatus === 'red'" pill variant="danger" class="float-right"><i class="flaticon2-cancel-music text-white"></i></b-badge>
            <h4>{{ CardTitle }}</h4>
            {{ CardText }}
            <!-- <button @click="goto(CardTitle)">#{{CardTitle}}</button> -->
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  <!-- <div :ref="CardTitle"></div> -->
  </b-card>
</template>

<script>
export default {
  name: "ConveyorCard",
  props: ['CardTitle', 'CardText', 'CardStatus', 'ButtonHref', 'imgUrl']
  // methods:{
  //   goto(refName) {
  //   	var element = this.$refs[refName];
  //     var top = element.offsetTop;
  //     console.log(element);
      
  //     window.scrollTo(0, top);
  //   }
  // }
};
</script>
