<template>
    <tr>
      <td width="30%" style="min-width: 100px">{{ label }} :</td>
      <td width="70%" style="min-width: 110px">
        <span class="text-dark-75 font-weight-bold font-size-lg" >
          {{ value }}
        </span>
      </td>
    </tr>
</template>

<script>
export default {
  name: "ConveyorTableRow",
  props: ['label', 'value']
};
</script>
