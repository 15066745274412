<template>
    <!-- <tr>
      <td style="min-width: 120px">{{ label }} :</td>
      <td style="min-width: 100px">
        <span class="text-dark-75 font-weight-bold font-size-lg" >
          {{ value }}
        </span>
      </td>
    </tr>-->
    <tr>
      <td style="min-width: 100px">{{ label }} :</td>
      <td style="min-width: 100px">
        <span v-if="value === 'orange'" class="label label-lg label-inline label-light-warning">
          <span class="svg-icon">
            <!-- <inline-svg src="media/svg/icons/Code/Warning-2.svg" /> -->
            <i class="flaticon2-warning text-warning"></i>
              <span
                class="label-light-warning label-inline font-weight-bold" 
              >
                orange
              </span>
          </span>
        </span>
        <span v-if="value === 'red'" class="label label-lg label-inline label-light-danger">
          <span class="svg-icon">
            <!-- <inline-svg src="media/svg/icons/Code/warning-1-circle.svg" /> -->
            <i class="flaticon-cancel text-danger"></i>
              <span
                class="label-light-danger label-inline font-weight-bold" 
              >
                red
              </span>
          </span>
        </span>
        <span v-if="value === 'green' || value === '200'" class="label label-lg label-inline label-light-success">
          <span class="svg-icon">
            <!-- <inline-svg src="media/svg/icons/Navigation/Check.svg" /> -->
            <i class="flaticon2-check-mark text-success"></i>
              <span
                class="label-light-success label-inline font-weight-bold"
              >
                green
              </span>
          </span>
        </span>
      </td>
    </tr>
</template>

<script>
export default {
  name: "ConveyorTableRowStatus",
  props: {
    label: String,
    value: String
  }
};
</script>
