<!--template v-for="(item, i) in lineInfo"-->
<style>
/* .sucess_massages{
  display: block;
    float: none;
    width: 100%;
    margin: 15px 0px 0px;
    text-align: center;
    font-size: 16px;
}
.sucess_massages .alert-success{
  max-width: 600px;
  margin: auto;
} */
</style>
<template>
  <div>
    <b-container class="bv-example-row">
      <b-row :class="{ 'd-none': isHideQuote }">
        <b-col>
          <div class="card card-custom gutter-b">
            <div class="card-header border-0 py-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark">
                  {{ $t("TRANSLATOR.GETQUOTE", { msg: "GETQUOTE" }) }}
                </span>
                <span class="text-muted mt-3 font-weight-bold font-size-sm"
                  >{{ $t("TRANSLATOR.LINE", { msg: "LINE" }) }}
                  {{ lineId }}</span
                >
              </h3>
              <div class="card-toolbar">
                <a
                  @click="GetQuoteToggl"
                  class="ml-5 btn btn-secondary font-weight-bolder font-size-sm"
                  >Hide quote</a
                >
              </div>
            </div>

            <div class="card-body">
              <!-- <form class="form"> -->
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right"
                  >To</label
                >
                <div class="col-lg-9 col-xl-6">
                  <input
                    ref="QuoteSubject"
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    :value="toSent"
                    @change="setTomail"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right"
                  >From</label
                >
                <div class="col-lg-9 col-xl-6">
                  <input
                    ref="QuoteSubject"
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    disabled
                    :value="userEmail"
                  />
                  <span class="form-text text-muted">{{ userName }}</span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right"
                  >Subject</label
                >
                <div class="col-lg-9 col-xl-6">
                  <input
                    ref="QuoteSubject"
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    v-model="QuoteSubject"
                    disabled
                  />
                  <!-- <span class="form-text text-muted">{{ QuoteSubject }}</span> -->
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right"
                  >Select parts
                </label>

                <div class="col-lg-9 col-xl-6">
                  <div
                    :key="row.id"
                    v-for="row in Parts"
                    class="d-flex align-items-center flex-grow-1"
                  >
                    <label
                      class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4"
                    >
                      <input
                        type="checkbox"
                        :id="row.id"
                        :value="row.PartsLabel"
                        @change="handleChange"
                      />
                      <span></span>
                    </label>
                    <div
                      class="d-flex flex-wrap align-items-center justify-content-between w-100"
                    >
                      <div
                        class="d-flex flex-column align-items-cente py-2 w-75"
                      >
                        <span
                          class="text-dark-75 font-weight-bold font-size-lg mb-1"
                          >{{ row.PartsLabel }}</span
                        >
                      </div>
                      <!-- <span class="label label-lg label-inline font-weight-bold py-4"
                    v-bind:class="`label-light-success`"
                    >status</span> -->
                      <!-- <span class="form-text text-muted">{{ row.id }}</span> -->
                    </div>
                  </div>
                  <!-- <div class="d-flex align-items-center flex-grow-1">
                <label class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                  <input 
                    ref="DriveBearingChecked"
                    type="checkbox"
                    v-model="DriveBearingChecked"
                  />
                  <span></span>
                </label>
                <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                  <div class="d-flex flex-column align-items-cente py-2 w-75">
                    <span class="text-dark-75 font-weight-bold font-size-lg mb-1"
                      >Drive Bearing</span>
                  </div>
                    <span class="form-text text-muted">{{ DriveBearingChecked }}</span>
                </div>
              </div>

              <div class="d-flex align-items-center flex-grow-1">
                <label class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                  <input 
                    ref="IdlerBearingChecked"
                    type="checkbox"
                    v-model="IdlerBearingChecked"
                  />
                  <span></span>
                </label>
                <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                  <div class="d-flex flex-column align-items-cente py-2 w-75">
                    <span class="text-dark-75 font-weight-bold font-size-lg mb-1"
                      >Idler Bearing</span>
                  </div>
                    <span class="form-text text-muted">{{ IdlerBearingChecked }}</span>
                </div>
              </div>

              <div class="d-flex align-items-center flex-grow-1">
                <label class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                  <input 
                    ref="ChainChecked"
                    type="checkbox"
                    v-model="ChainChecked"
                  />
                  <span></span>
                </label>
                <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                  <div class="d-flex flex-column align-items-cente py-2 w-75">
                    <span class="text-dark-75 font-weight-bold font-size-lg mb-1"
                      >Chain & Belt</span>
                  </div>
                    <span class="form-text text-muted">{{ ChainChecked }}</span>
                </div>
              </div>
              
              <div class="d-flex align-items-center flex-grow-1">
                <label class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                  <input 
                    ref="DriveSprocketChecked"
                    type="checkbox"
                    v-model="DriveSprocketChecked"
                  />
                  <span></span>
                </label>
                <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                  <div class="d-flex flex-column align-items-cente py-2 w-75">
                    <span class="text-dark-75 font-weight-bold font-size-lg mb-1"
                      >Drive Sprocket</span>
                  </div>
                    <span class="form-text text-muted">{{ DriveSprocketChecked }}</span>
                </div>
              </div>
              
              <div class="d-flex align-items-center flex-grow-1">
                <label class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                  <input 
                    ref="IdlerSprocketChecked"
                    type="checkbox"
                    v-model="IdlerSprocketChecked"
                  />
                  <span></span>
                </label>
                <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                  <div class="d-flex flex-column align-items-cente py-2 w-75">
                    <span class="text-dark-75 font-weight-bold font-size-lg mb-1"
                      >Idler Sprocket</span>
                  </div>
                    <span class="form-text text-muted">{{ IdlerSprocketChecked }}</span>
                </div>
              </div>
              
              <div class="d-flex align-items-center flex-grow-1">
                <label class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                  <input 
                    ref="CurveChecked"
                    type="checkbox"
                    v-model="CurveChecked"
                  />
                  <span></span>
                </label>
                <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                  <div class="d-flex flex-column align-items-cente py-2 w-75">
                    <span class="text-dark-75 font-weight-bold font-size-lg mb-1"
                      >Curve</span>
                  </div>
                    <span class="form-text text-muted">{{ CurveChecked }}</span>
                </div>
              </div>
              
              <div class="d-flex align-items-center flex-grow-1">
                <label class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                  <input 
                    ref="WearstripChecked"
                    type="checkbox"
                    v-model="WearstripChecked"
                  />
                  <span></span>
                </label>
                <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                  <div class="d-flex flex-column align-items-cente py-2 w-75">
                    <span class="text-dark-75 font-weight-bold font-size-lg mb-1"
                      >Wearstrip</span>
                  </div>
                    <span class="form-text text-muted">{{ WearstripChecked }}</span>
                </div>
              </div>
              
              <div class="d-flex align-items-center flex-grow-1">
                <label class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                  <input 
                    ref="RollerChecked"
                    type="checkbox"
                    v-model="RollerChecked"
                  />
                  <span></span>
                </label>
                <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                  <div class="d-flex flex-column align-items-cente py-2 w-75">
                    <span class="text-dark-75 font-weight-bold font-size-lg mb-1"
                      >Roller</span>
                  </div>
                    <span class="form-text text-muted">{{ RollerChecked }}</span>
                </div>
              </div>
              
              <div class="d-flex align-items-center flex-grow-1">
                <label class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                  <input 
                    ref="ComponentsChecked"
                    type="checkbox"
                    v-model="ComponentsChecked"
                  />
                  <span></span>
                </label>
                <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                  <div class="d-flex flex-column align-items-cente py-2 w-75">
                    <span class="text-dark-75 font-weight-bold font-size-lg mb-1"
                      >Components</span>
                  </div>
                    <span class="form-text text-muted">{{ ComponentsChecked }}</span>
                </div>
              </div> -->
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label text-right"
                  >Message</label
                >
                <div class="col-lg-9 col-xl-6">
                  <textarea
                    ref="QuoteMessage"
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    v-model="QuoteMessage"
                    rows="10"
                  />
                  <!-- <span style="white-space: pre-line;" class="form-text text-muted">{{ QuoteMessageHtml }}</span> -->
                </div>
              </div>
              <b-row class="mt-10">
                <b-col xs="6" class="text-center">
                  <b-button
                    type="reset"
                    class="mt-3 w-100"
                    size="md"
                    @click="resetGetQuote"
                    >Reset</b-button
                  >
                </b-col>
                <b-col xs="6" class="text-center">
                  <!-- <b-button class="mt-3 w-100" variant="primary" disabled size="md" v-if="!QuoteSubject && QuoteMessage === null">Submit</b-button> -->
                  <b-button
                    type="submit"
                    class="mt-3 w-100"
                    variant="primary"
                    size="md"
                    @click="submitGetQuote"
                    v-if="QuoteSubject"
                    >Submit</b-button
                  >
                </b-col>
              </b-row>
              <b-row class="sucess_massages mt-10">
                <b-col xs="12" class="text-center"></b-col>
                <b-col xs="12" class="text-center">
                  <!-- <b-alert variant="success" show>Success Alert</b-alert> -->
                  <b-alert
                    :show="dismissCountDown"
                    dismissible
                    fade
                    variant="success"
                    @dismiss-count-down="countDownChanged"
                  >
                    Form submitted successfully
                  </b-alert>
                </b-col>
                <b-col xs="12" class="text-center"></b-col>
              </b-row>
              <!-- <alert :visible.sync="alertVisible"></alert> -->

              <!-- </form> -->
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- ROW 1 -->
      <b-row>
        <b-col>
          <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 py-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark">
                  {{ $t("TRANSLATOR.CONVEYOR", { msg: "CONVEYOR" }) }}
                  {{ lineInfo.conveyorNumber }}
                </span>

                <span class="text-muted mt-3 font-weight-bold font-size-sm"
                  >{{ $t("TRANSLATOR.LINE", { msg: "LINE" }) }}
                  {{ lineId }}</span
                >

                <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
                  newstatus
                }}</span>
                <label class="lsi_date">
                  Last inspected {{ UpdatedData }}
                </label>
              </h3>

              <div class="card-toolbar">
                <b-button @click="InsertInspect" class="ml-5">Inspect button</b-button>

                <a
                  @click="GetQuoteToggl"
                  v-b-tooltip.hover.top="
                    'Receive quote to replace used spare parts'
                  "
                  class="ml-5 btn btn-primary font-weight-bolder font-size-sm"
                  >{{ $t("TRANSLATOR.GETQUOTE", { msg: "GETQUOTE" }) }}</a
                >
                                
                <div v-if="isAdmin === 'True'" class="dropdown dropdown-inline ml-5">
                  <Dropdown3
                    @statusChange="handleStatusChange"
                    :lineNumber="lineInfo.lineNumber"
                    :companyId="lineInfo.companyId"
                    :conveyorNumber="lineInfo.conveyorNumber"
                  ></Dropdown3>
                </div>
              </div>
            </div>
            <div class="card-body">
              <b-row class="mt-8">
                <b-col cols="6" @click="goto('DriveSprockets')">
                  <ConveyorCard
                    imgUrl="media/max-line/motors.jpg"
                    CardTitle="Motors"
                    :CardText="lineInfo.motorCode"
                    :CardStatus="lineInfo.motorStatus"
                  ></ConveyorCard>
                </b-col>
                <b-col cols="6" @click="goto('ChainBelt')">
                  <ConveyorCard
                    imgUrl="media/max-line/bearings.jpg"
                    CardTitle="Bearings"
                    :CardText="lineInfo.driveBearingCode"
                    :CardStatus="lineInfo.driveBearingStatus"
                  ></ConveyorCard>
                </b-col>
              </b-row>
              <b-row class="mt-8">
                <b-col cols="6" @click="goto('Curves')">
                  <ConveyorCard
                    imgUrl="media/max-line/sprockets.jpg"
                    CardTitle="Sprockets"
                    :CardText="lineInfo.driveSprocketCode"
                    :CardStatus="lineInfo.driveSprocketStatus"
                  ></ConveyorCard>
                </b-col>
                <b-col cols="6" @click="goto('Wearstrip')">
                  <ConveyorCard
                    imgUrl="media/max-line/chainbelt.jpg"
                    CardTitle="Chain & belt"
                    :CardText="lineInfo.chainBeltCode"
                    :CardStatus="lineInfo.chainBeltStatus"
                  ></ConveyorCard>
                </b-col>
              </b-row>
              <b-row class="mt-8">
                <b-col cols="6" @click="goto('Rollers')">
                  <ConveyorCard
                    imgUrl="media/max-line/curves.jpg"
                    CardTitle="Curves"
                    :CardText="lineInfo.curveType"
                    :CardStatus="lineInfo.curveStatus"
                  ></ConveyorCard>
                </b-col>
                <b-col cols="6" @click="goto('Components')">
                  <ConveyorCard
                    imgUrl="media/max-line/wearstrip.jpg"
                    CardTitle="Wearstrip"
                    :CardText="lineInfo.wearstripName"
                    :CardStatus="lineInfo.wearstripStatus"
                  ></ConveyorCard>
                </b-col>
              </b-row>
              <b-row class="mt-8">
                <b-col cols="6" @click="goto('Components')">
                  <ConveyorCard
                    imgUrl="media/max-line/rollers.jpg"
                    CardTitle="Rollers"
                    :CardText="lineInfo.rollerCode"
                    :CardStatus="lineInfo.rollerStatus"
                  ></ConveyorCard>
                </b-col>
                <b-col cols="6" @click="goto('Components')">
                  <ConveyorCard
                    imgUrl="media/max-line/components.jpg"
                    CardTitle="Components"
                    :CardText="lineInfo.componentsName"
                    :CardStatus="lineInfo.componentsStatus"
                  ></ConveyorCard>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- ROW 2 -->
      <b-row>
        <b-col>
          <!--begin::Advance Table Widget 10-->
          <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 py-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark">
                  {{ $t("TRANSLATOR.CONVEYOR", { msg: "CONVEYOR" }) }}
                  {{ lineInfo.conveyorNumber }}
                </span>
                <span class="text-muted mt-3 font-weight-bold font-size-sm"
                  >{{ $t("TRANSLATOR.LINE", { msg: "LINE" }) }}
                  {{ lineId }}</span
                >
              </h3>
              <!-- <div class="card-toolbar">
        <a href="#" class="btn btn-info font-weight-bolder font-size-sm">New Report</a>
      </div> -->
              <!-- <div class="card-toolbar">
        <div class="dropdown dropdown-inline">
          <Dropdown3 :lineNumber="lineInfo.lineNumber" :companyId="lineInfo.companyId" :conveyorNumber="lineInfo.conveyorNumber"></Dropdown3>
        </div>
      </div> -->
            </div>
            <div class="card-body py-0">
              <div class="table-responsive">
                <table
                  class="table table-head-custom table-vertical-center striped"
                  id="kt_advance_table_widget_4"
                >
                  <tbody>
                    <ConveyorTableRow
                      :label="$t('TRANSLATOR.NAME', { msg: 'NAME' })"
                      :value="lineInfo.conveyorExtName"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      :label="$t('TRANSLATOR.LINE', { msg: 'LINE' })"
                      :value="lineInfo.lineNumber"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      :label="$t('TRANSLATOR.CONVEYOR', { msg: 'CONVEYOR' })"
                      :value="lineInfo.conveyorNumber"
                    ></ConveyorTableRow>
                    <!-- <ConveyorTableRow :label="$t('TRANSLATOR.LENGTH', { msg: 'LENGTH' })" :value="conveyor_length"></ConveyorTableRow> -->
                    <!-- <ConveyorTableRow :label="$t('TRANSLATOR.NUMBEROFTRACKS', { msg: 'NUMBEROFTRACKS' })" :value="line_nr"></ConveyorTableRow> -->
                    <ConveyorTableRow
                      label="Last check"
                      :value="lineInfo.updated"
                    ></ConveyorTableRow>
                    <!-- <tr>
                <td style="min-width: 100px">Edit status :</td>
                <td style="min-width: 100px">
                  <div id="example-1">
    <form class="vue-form" @submit.prevent="submit">
      <fieldset>
        <input type="text" name="name" id="name" required="" v-model="name">
        <p class="select">
          <select class="budget" v-model="status">
						<option value="green">Green</option>
						<option value="orange">Orange</option>
						<option value="red">Red</option>
					</select>
        </p>
      <div>
        <input type="submit" value="Save">
      </div>
    </fieldset>
  </form>
  <p> {{ newstatus }} </p>
                  </div>
                </td>
              </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card card-custom gutter-b" ref="Motors">
            <div class="card-header border-0 py-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Motor</span
                >
              </h3>
              <div class="card-toolbar">
                <div class="dropdown dropdown-inline">
                  <ChangeStatusPart
                    @statusChange="handleStatusChange"
                    :lineNumber="lineInfo.lineNumber"
                    :companyId="lineInfo.companyId"
                    :conveyorNumber="lineInfo.conveyorNumber"
                    sparePart="[Motor status]"
                    :currentStatus="lineInfo.motorStatus"
                    :userId="userId"
                    :userEmail="userEmail"
                    :userName="userName"
                  >
                  </ChangeStatusPart>
                </div>
              </div>
            </div>
            <div class="card-body py-0">
              <div class="table-responsive">
                <table
                  class="table table-head-custom table-vertical-center striped"
                  id="kt_advance_table_widget_4"
                >
                  <tbody>
                    <ConveyorTableRow
                      label="Name"
                      :value="lineInfo.motorName"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Code"
                      :value="lineInfo.motorCode"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Amount"
                      :value="lineInfo.motorAmount"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Notes"
                      :value="lineInfo.motorNotes"
                      v-show="lineInfo.motorNotes != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRowStatus
                      label="Status"
                      :value="lineInfo.motorStatus"
                    ></ConveyorTableRowStatus>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card card-custom gutter-b" ref="DriveBearings">
            <div class="card-header border-0 py-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Drive bearing</span
                >
              </h3>
              <div class="card-toolbar">
                <div class="dropdown dropdown-inline">
                  <ChangeStatusPart
                    @statusChange="handleStatusChange"
                    :lineNumber="lineInfo.lineNumber"
                    :companyId="lineInfo.companyId"
                    :conveyorNumber="lineInfo.conveyorNumber"
                    sparePart="[Drive bearing status]"
                    :currentStatus="lineInfo.driveBearingStatus"
                    :userId="userId"
                    :userEmail="userEmail"
                    :userName="userName"
                  >
                  </ChangeStatusPart>
                </div>
              </div>
            </div>
            <div class="card-body py-0">
              <!--begin::Table-->
              <div class="table-responsive">
                <table
                  class="table table-head-custom table-vertical-center striped"
                  id="kt_advance_table_widget_4"
                >
                  <tbody>
                    <ConveyorTableRow
                      label="Name"
                      :value="lineInfo.driveBearingName"
                      v-show="lineInfo.driveBearingName != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Code"
                      :value="lineInfo.driveBearingCode"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Amount"
                      :value="lineInfo.driveBearingAmount"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Notes"
                      :value="lineInfo.driveBearingNotes"
                      v-show="lineInfo.driveBearingNotes != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRowStatus
                      label="Status"
                      :value="lineInfo.driveBearingStatus"
                    ></ConveyorTableRowStatus>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
          </div>

          <div class="card card-custom gutter-b" ref="IdlerBearings">
            <div class="card-header border-0 py-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Idler bearing</span
                >
              </h3>
              <div class="card-toolbar">
                <div class="dropdown dropdown-inline">
                  <ChangeStatusPart
                    @statusChange="handleStatusChange"
                    :lineNumber="lineInfo.lineNumber"
                    :companyId="lineInfo.companyId"
                    :conveyorNumber="lineInfo.conveyorNumber"
                    sparePart="[Idler bearing status]"
                    :currentStatus="lineInfo.idlerBearingStatus"
                    :userId="userId"
                    :userEmail="userEmail"
                    :userName="userName"
                  >
                  </ChangeStatusPart>
                </div>
              </div>
            </div>
            <div class="card-body py-0">
              <!--begin::Table-->
              <div class="table-responsive">
                <table
                  class="table table-head-custom table-vertical-center striped"
                  id="kt_advance_table_widget_4"
                >
                  <tbody>
                    <ConveyorTableRow
                      label="Name"
                      :value="lineInfo.idlerBearingName"
                      v-show="lineInfo.idlerBearingName != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Code"
                      :value="lineInfo.idlerBearingCode"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Amount"
                      :value="lineInfo.idlerBearingAmount"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Notes"
                      :value="lineInfo.idlerBearingNotes"
                      v-show="lineInfo.idlerBearingNotes != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRowStatus
                      label="Status"
                      :value="lineInfo.idlerBearingStatus"
                    ></ConveyorTableRowStatus>
                  </tbody>
                </table>
              </div>
              <!--end::Table-->
            </div>
          </div>

          <div class="card card-custom gutter-b" ref="ChainBelt">
            <div class="card-header border-0 py-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Chain / Belt</span
                >
              </h3>
              <div class="card-toolbar">
                <div class="dropdown dropdown-inline">
                  <ChangeStatusPart
                    @statusChange="handleStatusChange"
                    :lineNumber="lineInfo.lineNumber"
                    :companyId="lineInfo.companyId"
                    :conveyorNumber="lineInfo.conveyorNumber"
                    sparePart="[Chain/Belt status]"
                    :currentStatus="lineInfo.chainBeltStatus"
                    :userId="userId"
                    :userEmail="userEmail"
                    :userName="userName"
                  >
                  </ChangeStatusPart>
                </div>
              </div>
            </div>
            <div class="card-body py-0">
              <div class="table-responsive">
                <table
                  class="table table-head-custom table-vertical-center striped"
                  id="kt_advance_table_widget_4"
                >
                  <tbody>
                    <ConveyorTableRow
                      label="Name"
                      :value="lineInfo.chainBeltName"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Code"
                      :value="lineInfo.chainBeltCode"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Tracks"
                      :value="lineInfo.chainBeltAmount"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Total length (mm)"
                      :value="lineInfo.chainBeltLength"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Notes"
                      :value="lineInfo.chainBeltNotes"
                      v-show="lineInfo.chainBeltNotes != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRowStatus
                      label="Status"
                      :value="lineInfo.chainBeltStatus"
                    ></ConveyorTableRowStatus>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card card-custom gutter-b" ref="DriveSprockets">
            <div class="card-header border-0 py-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Drive sprocket</span
                >
              </h3>
              <div class="card-toolbar">
                <div class="dropdown dropdown-inline">
                  <ChangeStatusPart
                    @statusChange="handleStatusChange"
                    :lineNumber="lineInfo.lineNumber"
                    :companyId="lineInfo.companyId"
                    :conveyorNumber="lineInfo.conveyorNumber"
                    sparePart="[Drive sprocket status]"
                    :currentStatus="lineInfo.driveSprocketStatus"
                    :userId="userId"
                    :userEmail="userEmail"
                    :userName="userName"
                  >
                  </ChangeStatusPart>
                </div>
              </div>
            </div>
            <div class="card-body py-0">
              <div class="table-responsive">
                <table
                  class="table table-head-custom table-vertical-center striped"
                  id="kt_advance_table_widget_4"
                >
                  <tbody>
                    <ConveyorTableRow
                      label="Name"
                      :value="lineInfo.driveSprocketName"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Code"
                      :value="lineInfo.driveSprocketCode"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Amount"
                      :value="lineInfo.driveSprocketAmount"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Notes"
                      :value="lineInfo.driveSprocketNotes"
                      v-show="lineInfo.driveSprocketNotes != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRowStatus
                      label="Status"
                      :value="lineInfo.driveSprocketStatus"
                    ></ConveyorTableRowStatus>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card card-custom gutter-b" ref="IdlerSprockets">
            <div class="card-header border-0 py-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Idler sprocket</span
                >
              </h3>
              <div class="card-toolbar">
                <div class="dropdown dropdown-inline">
                  <ChangeStatusPart
                    @statusChange="handleStatusChange"
                    :lineNumber="lineInfo.lineNumber"
                    :companyId="lineInfo.companyId"
                    :conveyorNumber="lineInfo.conveyorNumber"
                    sparePart="[Idler sprocket status]"
                    :currentStatus="lineInfo.idlerSprocketStatus"
                    :userId="userId"
                    :userEmail="userEmail"
                    :userName="userName"
                  >
                  </ChangeStatusPart>
                </div>
              </div>
            </div>
            <div class="card-body py-0">
              <div class="table-responsive">
                <table
                  class="table table-head-custom table-vertical-center striped"
                  id="kt_advance_table_widget_4"
                >
                  <tbody>
                    <ConveyorTableRow
                      label="Name"
                      :value="lineInfo.idlerSprocketName"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Code"
                      :value="lineInfo.idlerSprocketCode"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Amount"
                      :value="lineInfo.idlerSprocketAmount"
                      v-show="lineInfo.idlerSprocketAmount > 0"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Notes"
                      :value="lineInfo.idlerSprocketNotes"
                      v-show="lineInfo.idlerSprocketNotes != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRowStatus
                      label="Status"
                      :value="lineInfo.idlerSprocketStatus"
                    ></ConveyorTableRowStatus>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card card-custom gutter-b" ref="Curves">
            <div class="card-header border-0 py-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Curve</span
                >
              </h3>
              <div class="card-toolbar">
                <div class="dropdown dropdown-inline">
                  <ChangeStatusPart
                    @statusChange="handleStatusChange"
                    :lineNumber="lineInfo.lineNumber"
                    :companyId="lineInfo.companyId"
                    :conveyorNumber="lineInfo.conveyorNumber"
                    sparePart="[Curve status]"
                    :currentStatus="lineInfo.curveStatus"
                    :userId="userId"
                    :userEmail="userEmail"
                    :userName="userName"
                  >
                  </ChangeStatusPart>
                </div>
              </div>
            </div>
            <div class="card-body py-0">
              <div class="table-responsive">
                <table
                  class="table table-head-custom table-vertical-center striped"
                  id="kt_advance_table_widget_4"
                >
                  <tbody>
                    <ConveyorTableRow
                      label="Code"
                      :value="lineInfo.curveType"
                      v-show="lineInfo.curveType != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Amount"
                      :value="lineInfo.curveAmount"
                      v-show="lineInfo.curveAmount > 0"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Notes"
                      :value="lineInfo.curveNotes"
                      v-show="lineInfo.curveNotes != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRowStatus
                      label="Status"
                      :value="lineInfo.curveStatus"
                      v-show="lineInfo.curveStatus != ''"
                    ></ConveyorTableRowStatus>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card card-custom gutter-b" ref="Wearstrip">
            <div class="card-header border-0 py-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Wearstrip</span
                >
              </h3>
              <div class="card-toolbar">
                <div class="dropdown dropdown-inline">
                  <ChangeStatusPart
                    @statusChange="handleStatusChange"
                    :lineNumber="lineInfo.lineNumber"
                    :companyId="lineInfo.companyId"
                    :conveyorNumber="lineInfo.conveyorNumber"
                    sparePart="[Wearstrip status]"
                    :currentStatus="lineInfo.wearstripStatus"
                    :userId="userId"
                    :userEmail="userEmail"
                    :userName="userName"
                  >
                  </ChangeStatusPart>
                </div>
              </div>
            </div>
            <div class="card-body py-0">
              <div class="table-responsive">
                <table
                  class="table table-head-custom table-vertical-center striped"
                  id="kt_advance_table_widget_4"
                >
                  <tbody>
                    <ConveyorTableRow
                      label="Name"
                      :value="lineInfo.wearstripName"
                    ></ConveyorTableRow>
                    <!-- <ConveyorTableRow label="Code" :value="lineInfo.wearstripCode"></ConveyorTableRow> -->
                    <ConveyorTableRow
                      label="Amount"
                      :value="lineInfo.wearstripAmount"
                      v-show="lineInfo.wearstripAmount > 0"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Notes"
                      :value="lineInfo.wearstripNotes"
                      v-show="lineInfo.wearstripNotes != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Length"
                      :value="lineInfo.wearstripLength"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Brand"
                      :value="lineInfo.wearstripBrand"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Series"
                      :value="lineInfo.wearstripSeries"
                      v-show="lineInfo.wearstripSeries != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Width"
                      :value="lineInfo.wearstripWidth"
                      v-show="lineInfo.wearstripWidth != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="High"
                      :value="lineInfo.wearstripHigh"
                      v-show="lineInfo.wearstripHigh != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Tickness"
                      :value="lineInfo.wearstripThickness"
                      v-show="lineInfo.wearstripThickness != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Color"
                      :value="lineInfo.wearstripColor"
                      v-show="lineInfo.wearstripColor != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRowStatus
                      label="Status"
                      :value="lineInfo.wearstripStatus"
                    ></ConveyorTableRowStatus>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card card-custom gutter-b" ref="Rollers">
            <div class="card-header border-0 py-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Roller</span
                >
              </h3>
              <div class="card-toolbar">
                <div class="dropdown dropdown-inline">
                  <ChangeStatusPart
                    @statusChange="handleStatusChange"
                    :lineNumber="lineInfo.lineNumber"
                    :companyId="lineInfo.companyId"
                    :conveyorNumber="lineInfo.conveyorNumber"
                    sparePart="[Roller status]"
                    :currentStatus="lineInfo.rollerStatus"
                    :userId="userId"
                    :userEmail="userEmail"
                    :userName="userName"
                  >
                  </ChangeStatusPart>
                </div>
              </div>
            </div>
            <div class="card-body py-0">
              <div class="table-responsive">
                <table
                  class="table table-head-custom table-vertical-center striped"
                  id="kt_advance_table_widget_4"
                >
                  <tbody>
                    <ConveyorTableRow
                      label="Name"
                      :value="lineInfo.rollerName"
                      v-show="lineInfo.rollerName != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Code"
                      :value="lineInfo.rollerCode"
                    ></ConveyorTableRow>
                    <!-- <ConveyorTableRow label="Length" :value="lineInfo.rollerLength"></ConveyorTableRow> -->
                    <ConveyorTableRow
                      label="Notes"
                      :value="lineInfo.rollerNotes"
                      v-show="lineInfo.rollerNotes != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRowStatus
                      label="Status"
                      :value="lineInfo.rollerStatus"
                    ></ConveyorTableRowStatus>
                    <ConveyorTableRow
                      label="Series"
                      :value="lineInfo.rollerSeries"
                      v-show="lineInfo.rollerSeries != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Width"
                      :value="lineInfo.rollerWidth"
                      v-show="lineInfo.rollerWidth != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="High"
                      :value="lineInfo.rollerShaftdiameter"
                      v-show="lineInfo.rollerShaftdiameter != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Diameter"
                      :value="lineInfo.rollerBoreDiameter"
                      v-show="lineInfo.rollerBoreDiameter != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Outside diameter"
                      :value="lineInfo.rollerOutsideDiameter"
                      v-show="
                        lineInfo.rollerOutsideDiameter != null &&
                        lineInfo.rollerOutsideDiameter != ''
                      "
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Color"
                      :value="lineInfo.rollerMaterial"
                      v-show="lineInfo.rollerMaterial != ''"
                    ></ConveyorTableRow>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card card-custom gutter-b" ref="Components">
            <div class="card-header border-0 py-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Components</span
                >
              </h3>
              <div class="card-toolbar">
                <div class="dropdown dropdown-inline">
                  <ChangeStatusPart
                    @statusChange="handleStatusChange"
                    :lineNumber="lineInfo.lineNumber"
                    :companyId="lineInfo.companyId"
                    :conveyorNumber="lineInfo.conveyorNumber"
                    sparePart="[Components status]"
                    :currentStatus="lineInfo.componentsNotes"
                    :userId="userId"
                    :userEmail="userEmail"
                    :userName="userName"
                  >
                  </ChangeStatusPart>
                </div>
              </div>
            </div>
            <div class="card-body py-0">
              <div class="table-responsive">
                <table
                  class="table table-head-custom table-vertical-center striped"
                  id="kt_advance_table_widget_4"
                >
                  <tbody>
                    <ConveyorTableRow
                      label="Name"
                      :value="lineInfo.componentsName"
                      v-show="lineInfo.componentsName != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Code"
                      :value="lineInfo.componentsCode"
                      v-show="lineInfo.componentsCode != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Amount"
                      :value="lineInfo.componentsAmount"
                      v-show="lineInfo.componentsAmount > 0"
                    ></ConveyorTableRow>
                    <ConveyorTableRow
                      label="Notes"
                      :value="lineInfo.componentsNotes"
                      v-show="lineInfo.componentsNotes != ''"
                    ></ConveyorTableRow>
                    <ConveyorTableRowStatus
                      label="Status"
                      :value="lineInfo.componentsStatus"
                      v-show="lineInfo.componentsStatus != ''"
                    ></ConveyorTableRowStatus>
                    <ConveyorTableRow
                      label="Series"
                      :value="lineInfo.componentsSeries"
                      v-show="lineInfo.componentsSeries != ''"
                    ></ConveyorTableRow>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card card-custom gutter-b" ref="Components">
            <div class="card-header border-0 py-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Maintenance history</span
                >
              </h3>
            </div>
            <div class="card-body py-0">
              <HistoryTable v-bind:lineHistory="lineHistory" :lineId="lineId" :conveyorId="conveyorId"></HistoryTable>

              <!-- <div class="table-responsive">
        <table class="table table-head-custom table-vertical-center striped" id="kt_advance_table_widget_4">
          <tbody>
    <tr>
      <td style="min-width: 100px">
        28/11/2020 : The Status for the <b>Chain/Belt</b> is changed from Red into 
          <span class="label label-lg label-inline label-light-success">
            <span
              class="label-light-success label-inline font-weight-bold"
            >
              green
            </span>
          </span>
        by <b>{{ companyId }}</b>
      </td>
    </tr>
    <tr>
      <td style="min-width: 100px">
        05/04/2019 : The Status for the <b>bearing</b> is changed from green into 
          <span class="label label-lg label-inline label-light-danger">
            <span
              class="label-light-success label-inline font-weight-bold"
            >
              red
            </span>
          </span>
        by <b><a :href="'mailto:'+userEmail">{{ userName }}</a></b>
      </td>
    </tr>
          </tbody>
        </table>
      </div> -->
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ConveyorTableRow from "@/view/pages/conveyor/ConveyorTableRow";
import ConveyorTableRowStatus from "@/view/pages/conveyor/ConveyorTableRowStatus";
import HistoryTable from "@/view/pages/conveyor/HistoryTable";
import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";
import ChangeStatusPart from "@/view/content/dropdown/ChangeStatusPart.vue";
import ConveyorCard from "@/view/pages/conveyor/ConveyorCard.vue";
import JwtService from "@/core/services/jwt.service";
import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert, { reverseButtons: true });
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

// import VueBasicAlert from 'vue-basic-alert'
export default {
  name: "Header",
  props: {
    lineId: String,
    breadcrumbs: Array,
    // conveyorId: String
    conveyorId: String,
    // currentStatus: String
  },
  data() {
    // try {
    //   this.$root.$on("routeChangeComplete", this.handleRouteChange);
    //   console.log("On routeChangeComplete");
    //   // from the event with the `off` method:
    //   return () => {
    //     console.log("On routeChangeComplete On");
    //     this.$root.off("routeChangeComplete", this.handleRouteChange);
    //   };
    // } catch (err) {
    //   console.log(err);
    // }
    return {
      lineInfo: {},
      lineHistory: {},
      selected: "",
      UpdatedData: "",
      name: "Vue.js",
      newstatus: null,
      isHideQuote: true,
      // Get Quote
      QuoteSubject: null,
      QuoteMessage: null,
      selectedParts: [],
      Parts: [
        { id: "1", PartsLabel: "Motor" },
        { id: "2", PartsLabel: "Drive Bearing" },
        { id: "3", PartsLabel: "Idler Bearing" },
        { id: "4", PartsLabel: "Chain & Belt" },
        { id: "5", PartsLabel: "Drive Sprocket" },
        { id: "6", PartsLabel: "Idler Sprocket" },
        { id: "7", PartsLabel: "Curve" },
        { id: "8", PartsLabel: "Wearstrip" },
        { id: "9", PartsLabel: "Roller" },
        { id: "10", PartsLabel: "Components" },
      ],
      // lineId: 1,
      submitted: false,
      // checked: false,
      sparePart: null,
      apiConveyor: null,
      userId: JwtService.authorizeToken().id,
      userName: JwtService.authorizeToken().name,
      userEmail: JwtService.authorizeToken().emailaddress,
      companyId: JwtService.authorizeToken().companyId,
      toSent: "maxim.vangammeren@max-line.nl",
      isAdmin: JwtService.authorizeToken().isAdmin,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
    };
  },
  components: {
    ConveyorTableRow,
    ConveyorTableRowStatus,
    HistoryTable,
    ConveyorCard,
    Dropdown3,
    ChangeStatusPart,
  },
  methods: {
    handleRouteChange() {
      // if (url !== prevPath) {
      // 	prevPath = url;
      // 	// call All page load event on route change
      // 	AllPagesGA({session,event:'page_changed'});
    },
    // submit form handler
    submit: function () {
      this.submitted = true;
    },
    handleStatusChange() {
      this.fetchLineData();
      this.fetchLineHistory();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    goToHome(type) {
      if(this.isHideQuote == false){
      this.isHideQuote = !this.isHideQuote;
      }
      if (type == "next") {
        this.conveyorId++;
      } else {
        this.conveyorId--;
      }
      this.conveyorId = this.conveyorId;

      axios
        .get("line/" + this.lineId + "/conveyor/" + this.conveyorId)
        .then((response) => {
          this.lineInfo = response.data;
          this.UpdatedData = response.data.updated;
    this.fetchLineHistory();

        })
        .catch((err) => {
          throw err;
        });
    },

    // onEdit: function (event) {
    //   // `this` inside methods points to the Vue instance
    //   console.log('Hello ' + this.name + '!')
    //   // `event` is the native DOM event
    //   if (event) {
    //     console.log(event.target.tagName)
    //   }
    // },
    getStatusClass: (status) => {
      switch (status) {
        case "red":
          return "danger";
        case "orange":
          return "warning";
        case "green":
          return "success";
      }
    },
    goto(refName) {
      var element = this.$refs[refName];
      // console.log(element);
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    fetchLineData() {
      axios
        .get("line/" + this.lineId + "/conveyor/" + this.conveyorId)
        .then((response) => {
          this.lineInfo = response.data;
          this.UpdatedData = response.data.updated;
          // console.log(this.lineInfo.driveBearingName);
        })
        .catch((err) => {
          throw err;
        });
    },
    fetchLineHistory() {
      axios
        .get(
          "line/" + this.lineId + "/conveyor/" + this.conveyorId + "/history"
        )
        .then((response) => {
          this.lineHistory = response.data;
        })
        .catch((err) => {
          throw err;
        });
    },

    InsertInspect() {
      axios
        .post("line/Insertinspect", {
          CompanyId: this.companyId,
          LineNumber: this.lineInfo.lineNumber,
          ConveyorNumber: this.lineInfo.conveyorNumber,
          UserId: this.userId,
          Note: "inspected to Survey",
        })
        .then(() => {
          axios
            .get("line/" + this.lineId + "/conveyor/" + this.conveyorId)
            .then((response) => {
              this.lineInfo = response.data;
              this.UpdatedData = response.data.updated;
              this.fetchLineHistory();
              // console.log(this.lineInfo.driveBearingName);
            })
            .catch((err) => {
              throw err;
            });
        })
        .catch((err) => {
          throw err;
        });
      // axios
      //   .get(
      //     "line/" + this.lineId + "/conveyor/" + this.conveyorId + "/history"
      //   )
      //   .then((response) => {
      //     this.lineHistory = response.data;
      //     // console.log(this.lineInfo.driveBearingName);
      //   })
      //   .catch((err) => {
      //     throw err;
      //   });
    },
    submitGetQuote: function () {
      // console.log("Message : " + this.QuoteMessage)
      // console.log("ok")
      // Execute Update Conveyor Status
      axios
        .post("line/sendQuote", {
          CompanyId: this.companyId,
          LineNumber: this.lineNumber,
          ConveyorNumber: this.conveyorNumber,
          UserEmail: this.userEmail,
          QuoteSubject: this.QuoteSubject,
          QuoteMessage: this.QuoteMessageHtml,
          ToMail: this.toSent,
        })
        .then(() => {
          this.dismissCountDown = this.dismissSecs;
          // <VueBasicAlert />
          // Reload page with new status
          // this.$emit('statusChange')
        })
        .catch((err) => {
          throw err;
        });
    },
    resetGetQuote: function () {
      this.QuoteMessage = null;
    },
    GetQuoteToggl() {
      this.isHideQuote = !this.isHideQuote;
      // console.log(this.isHideQuote)
      this.QuoteSubject =
        "Max-line Quote : Factory " +
        this.companyId +
        " > " +
        "line " +
        this.lineId +
        " > conveyor " +
        this.conveyorId;
      this.QuoteMessage =
        "Hello,\n\nI would like to get a quote for :\nFactory " +
        this.companyId +
        " > " +
        " line " +
        this.lineId +
        " > conveyor " +
        this.conveyorId +
        "\n\nFor these spare parts :";
      this.QuoteMessage += "\n" + this.selectedParts;
      this.QuoteMessage += "\n\n" + this.userName;
      this.QuoteMessageHtml = this.QuoteMessage.replace(
        /(?:\r\n|\r|\n)/g,
        "<br />"
      );
    },
    handleChange(e) {
      const { value, checked } = e.target;
      if (checked) {
        this.selectedParts.push(value);
      } else {
        const index = this.selectedParts.findIndex((id) => id === value);
        if (index > -1) {
          this.selectedParts.splice(index, 1);
        }
      }
      this.QuoteSubject =
        "Max-line Quote : Factory " +
        this.companyId +
        " > " +
        "line " +
        this.lineId +
        " > conveyor " +
        this.conveyorId;
      this.QuoteMessage =
        "Hello,\n\nI would like to get a quote for :\nFactory " +
        this.companyId +
        " > " +
        " line " +
        this.lineId +
        " > conveyor " +
        this.conveyorId +
        "\n\nFor these spare parts :";
      this.QuoteMessage += "\n" + this.selectedParts.sort();
      this.QuoteMessage += "\n\n" + this.userName;
      this.QuoteMessageHtml = this.QuoteMessage.replace(
        /(?:\r\n|\r|\n)/g,
        "<br />"
      );
      // console.log(this.QuoteMessageHtml.replace((/\n/g, "<br />")))
    },
    setTomail(e) {
      this.toSent = e.target.value;
    },
  },

  mounted() {
    this.$root.$on("GoToNext", () => {
      // your code goes here
      this.goToHome("next");
    }),
      this.$root.$on("GoToPrev", () => {
        // your code goes here
        this.goToHome("prev");
      }),
      this.fetchLineData();
    this.fetchLineHistory();
    // console.log(this.isAdmin)
    // this.fetchLineInfo();
  },
};
</script>
